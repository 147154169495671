<template>
    <div>
        <!-- Hero Start -->
        <v-lazy
            v-model="isActive"
            :options="{
                threshold: 0.5,
            }"
            min-height="200"
            transition="fade-transition"
        >
            <section class="bg-half-170 d-table w-100" id="home">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <div class="title-heading mt-4">
                                <!-- <div class="alert alert-light alert-pills shadow" role="alert">
                                    <span class="badge badge-pill badge-danger mr-1">BETA</span>
                                    <span class="content">
                                    <span class="text-primary">Trade-Raid</span> Get more value out of your items</span
                                    >
                                </div> -->
                                <!-- <h1 class="heading mb-3 text-dark">
                                    Give your
                                </h1>
                                    <div class="s-move">
                                        <div class="s-move__container">
                                            <ul class="s-move__container__list">
                                                <li class="s-move__container__list__item">CS:GO</li>
                                                <li class="s-move__container__list__item">DOTA2</li>
                                                <li class="s-move__container__list__item">RUST</li>
                                                <li class="s-move__container__list__item">TF2</li>
                                                <li class="s-move__container__list__item">STEAM</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 class="heading mb-3 text-dark">
                                    Items new value 
                                </h1> -->
                                <header_slider />
                                <p class="para-desc text-muted" style="padding-top: 20px; font-weight: bold">
                                    Don’t swipe your credit card twice.
                                    <br />Pay with your Skins or own Gold.
                                </p>
                                <div class="mt-4">
                                    <router-link
                                        to="/services"
                                        class="btn btn-outline-primary rounded"
                                        style="padding: 8px 45px"
                                        ><i class="mdi mdi-handshake"></i> Try Now</router-link
                                    >
                                </div>
                            </div>
                        </div>
                        <!--end col-->

                        <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div class="position-relative">
                                <headerImage />
                                <!-- <img
                                src="/images/floating_platforms.svg"
                                class="rounded img-fluid mx-auto d-block cls-10"
                                alt="trade-raid services"
                            /> -->
                                <!-- <div class="play-icon">
                            <a
                            href="javascript: void(0);"
                            v-b-modal.modal-1
                            class="play-btn border border-light mt-4 video-play-icon"
                            >
                            <i
                                class="mdi mdi-play text-primary rounded-circle bg-white shadow"
                            ></i>
                            </a>
                            <b-modal
                            id="modal-1"
                            hide-footer
                            size="lg"
                            header-close-variant="white"
                            header-class="border-0"
                            content-class="border-0"
                            centered
                            >
                            <youtube video-id="D1LrOLP4bPQ" ref="youtube" width="750" height="450"></youtube>
                            </b-modal>
                        </div> -->
                            </div>
                        </div>
                        <!--end col-->
                    </div>
                    <div class="trust-pilot-padding-fix">
                        <trustpilot-carousel></trustpilot-carousel>
                    </div>

                    <!--end row-->
                </div>
                <!--end container-->
            </section>
        </v-lazy>
        <!--end section-->
        <!-- Hero End -->
        <v-lazy
            v-model="isActive"
            :options="{
                threshold: 0.5,
            }"
            min-height="200"
            transition="fade-transition"
        >
            <section class="">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-12 mt-4 pt-2">
                            <div class="card text-center bg-primary rounded border-0">
                                <div class="card-body">
                                    <div class="p-1 bg-light rounded shadow d-inline-block">
                                        <!-- <clock-icon size="2x" class="icon-class"></clock-icon> -->
                                        <img
                                            src="https://trade-raid-com.mo.cloudinary.net/clock.png"
                                            class="avatar avatar-medium"
                                            alt="Clock image"
                                            style="width: 100%; height: auto"
                                        />
                                    </div>
                                    <div class="mt-2">
                                        <h4 class="text-dark">Established in 2020</h4>
                                        <p class="text-white-100 mt-1 mb-0 text-dark">
                                            Helped thousands of gamers swap their gold and skins between games.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end col-->

                        <div class="col-md-4 col-12 mt-4 pt-2">
                            <div class="card text-center bg-primary rounded border-0">
                                <div class="card-body">
                                    <div class="p-3 bg-white rounded shadow d-inline-block">
                                        <!-- <shopping-bag-icon size="2x" class="icon-class"></shopping-bag-icon> -->
                                        <img
                                            src="https://trade-raid-com.mo.cloudinary.net/coins.png"
                                            class="avatar avatar-medium"
                                            alt="Coin image"
                                            style="width: 100%; height: auto"
                                        />
                                    </div>
                                    <div class="mt-2">
                                        <h4 class="text-dark">Gold on all realms</h4>
                                        <p class="text-white-100 mt-1 mb-0 text-dark">
                                            We deliver gold to all realms and servers with few exceptions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end col-->

                        <div class="col-md-4 col-12 mt-4 pt-2">
                            <div class="card text-center bg-primary rounded border-0">
                                <div class="card-body">
                                    <div class="p-1 bg-light rounded shadow d-inline-block">
                                        <!-- <star-icon size="2x" class="icon-class"></star-icon> -->
                                        <a href="https://uk.trustpilot.com/review/trade-raid.com" target="_blank">
                                            <img
                                                src="https://trade-raid-com.mo.cloudinary.net/tick.png"
                                                class="avatar avatar-medium"
                                                alt="Tick image"
                                                style="width: 100%; height: auto"
                                            />
                                        </a>
                                    </div>
                                    <div class="mt-2">
                                        <h4>
                                            <a
                                                href="https://uk.trustpilot.com/review/trade-raid.com"
                                                target="_blank"
                                                class="text-dark"
                                                >High Trustpilot Score</a
                                            >
                                        </h4>
                                        <p class="text-white-100 mt-1 mb-0 text-dark">
                                            Our services and support are praised by our users.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
            </section>
        </v-lazy>

        <!-- Work Process Start -->
        <v-lazy
            v-model="isActive"
            :options="{
                threshold: 0.5,
            }"
            min-height="200"
            transition="fade-transition"
        >
            <section>
                <!--(.work-process) css write in (_feature.scss)-->
                <div class="container overwrite-container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title mb-4 pb-2">
                                <h4 class="title mb-4" style="font-weight: 400">
                                    Don't buy WoW Gold.

                                    <bold style="font-weight: bold">Trade your own Gold or Steam Items instead!</bold>
                                </h4>
                                <!-- <p class="text-muted para-desc mx-auto mb-0">
                                Services
                            </p> -->
                                <!-- Service Info-->
                                <div class="service-container">
                                    <div class="service">
                                        <div class="service-info align-baseline">
                                            <h2 class="title mb-4 text-dark">Trade Service</h2>
                                            <h4
                                                class="title mb-4 text-dark"
                                                style="font-weight: 400 400; text-align: left"
                                            >
                                                Trade your Steam Items for WoW Gold
                                            </h4>
                                            <a
                                                href="https://trade-raid.com/trade"
                                                class="btn btn-outline-primary mt-2 mr-5"
                                                >Trade Service</a
                                            >
                                        </div>
                                        <img
                                            src="/images/ts_service.png"
                                            alt="Trade Raid - Trade Service"
                                            class="service-image"
                                        />
                                    </div>
                                    <div class="service align-center row-reverse">
                                        <div class="service-info align-baseline">
                                            <h2 class="title mb-4 text-dark">Exchange Service</h2>
                                            <h4 class="title mb-4 text-dark" style="font-weight: 400; text-align: left">
                                                Swap your gold between Retail and Classic (both ways)
                                            </h4>
                                            <a
                                                href="https://trade-raid.com/exchange"
                                                class="btn btn-outline-primary mt-2 mr-5"
                                                >Exchange Service</a
                                            >
                                        </div>
                                        <img
                                            class="service-image"
                                            src="/images/es_service_new.png"
                                            alt="Trade Raid - Exchange Service"
                                        />
                                    </div>
                                </div>

                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="col-12 text-center">
                                            <div class="section-title">
                                                <!-- <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p> -->

                                                <!-- <div class="mt-4">
                                <a href="https://trade-raid.com/trade"  class="btn btn-outline-primary mt-2 mr-5 trade-service"
                                    >Trade Service</a
                                >
                                <a href="https://trade-raid.com/exchange" class="btn btn-outline-primary mt-2 mr-5 exchange-service">Exchange Service</a>
                            </div> -->
                                            </div>
                                        </div>
                                        <!--end col-->
                                    </div>
                                    <!--end row-->
                                </div>
                            </div>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                    <!--end row-->
                </div>
                <!--end container-->
            </section>
        </v-lazy>

        <v-lazy
            v-model="isActive"
            :options="{
                threshold: 0.5,
            }"
            min-height="200"
            transition="fade-transition"
        >
            <section>
                <!-- Counter End -->
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="section-title">
                                <h4 class="title mb-4 text-dark">
                                    Our services are made <br />
                                    <span class="text-primary">by gamers for gamers</span>
                                </h4>
                                <p class="text-muted para-desc">
                                    Trade-Raid has helped thousands of gamers over the years to move their digital goods
                                    across games and servers.
                                    <br /><br />We share the frustration gamers face when their value is locked behind
                                    artificial barriers within a game or on a server. Our services break these barriers
                                    down so your hard-earned items and gold are with you wherever you go.
                                </p>
                                <!-- <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Digital Marketing Solutions for Tomorrow
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Our Talented & Experienced Marketing Agency
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Create your own skin to match your brand
                </li>
              </ul> -->
                            </div>
                        </div>
                        <!--end col-->

                        <div class="col-lg-6">
                            <div class="row ml-lg-5" id="counter">
                                <div class="col-md-6 col-12">
                                    <div class="row">
                                        <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                            <div class="card counter-box border-0 bg-light shadow text-center rounded">
                                                <div class="card-body py-5">
                                                    <h2 class="mb-0 text-dark">
                                                        <countTo
                                                            :startVal="0"
                                                            :endVal="6500"
                                                            :duration="7000"
                                                            decimals="0"
                                                        ></countTo
                                                        >+
                                                    </h2>
                                                    <h5 class="counter-head mb-0 text-dark">Orders completed</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <!--end col-->

                                        <div class="col-12 mt-4 pt-2">
                                            <div
                                                class="card counter-box border-0 bg-primary shadow text-center rounded"
                                            >
                                                <div class="card-body py-5">
                                                    <h2 class="text-light title-dark mb-0">
                                                        <countTo :startVal="1" :endVal="25" :duration="7000"></countTo>M
                                                    </h2>
                                                    <h5 class="counter-head mb-0 title-dark text-light">
                                                        Gold rewarded in giveaways
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <!--end col-->
                                    </div>
                                    <!--end Row-->
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-12">
                                    <div class="row pt-lg-4 mt-lg-4">
                                        <div class="col-12 mt-4 pt-2">
                                            <div
                                                class="card counter-box border-0 bg-success shadow text-center rounded"
                                            >
                                                <div class="card-body py-5">
                                                    <h2 class="text-light title-dark mb-0">
                                                        <countTo :startVal="0" :endVal="13000" :duration="7000">
                                                        </countTo
                                                        >+
                                                    </h2>
                                                    <h5 class="counter-head mb-0 title-dark text-light">
                                                        registered users
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <!--end col-->

                                        <div class="col-12 mt-4 pt-2">
                                            <div class="card counter-box border-0 bg-light shadow text-center rounded">
                                                <div class="card-body py-5">
                                                    <!-- <h2 class="mb-0 text-dark">
                          <countTo
                            :startVal="3"
                            :endVal="98"
                            :duration="4000"
                          ></countTo
                          >Live Chat and Mail Support
                        </h2> -->
                                                    <h5 class="counter-head mb-0 text-dark">
                                                        Live Chat, Mail & Discord Support
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <!--end col-->
                                    </div>
                                    <!--end Row-->
                                </div>
                                <!--end col-->
                            </div>
                            <!--end Row-->
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <br /><br /><br />
                <!--end container-->
            </section>
        </v-lazy>
        <!--end section-->
        <!-- Counter End -->

        <!-- News Start -->
        <div class="container tr-blog">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <a href="/blog" class="text-primary h6"
                            ><h4 class="title mb-4 text-dark">Trade-Raid Blog & Guides</h4></a
                        >
                        <p class="text-muted para-desc mx-auto mb-0">
                            See our latest blog posts to know what we are up to at
                            <a href="/blog" class="text-primary h6"
                                ><span class="text-primary font-weight-bold">Trade-Raid</span></a
                            >!
                        </p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
            <div class="blog">
                <blog-news></blog-news>
            </div>
        </div>
        <!--end container-->
        <!-- News End -->
        <section class="section bg-light">
            <!--(.work-process) css write in (_feature.scss)-->
            <div class="container overwrite-container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4">
                            <h4 class="title mb-4 text-dark">
                                Trade-Raid has been saving gamers' cash since 2020. <br />
                                Use our services to move your value to where it actually matters.
                                <!-- Pay any way you want - Gold, Skins or Cash.<br />We got you covered. -->
                            </h4>
                        </div>
                        <router-link
                            to="/services"
                            class="btn btn-outline-primary rounded"
                            style="margin-right: 10px !important"
                            ><i class="mdi mdi-handshake"></i> Trade Now
                        </router-link>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
        </section>
        <!--end section-->

        <!-- Back to top -->
        <div class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#header'">
            <arrow-up-icon class="icons"></arrow-up-icon>
        </div>
        <!-- Back to top -->
    </div>
</template>

<script>
import { VueTyper } from 'vue-typer';
import { ArrowUpIcon, PlayIcon, ClockIcon, ShoppingBagIcon, StarIcon, EyeIcon } from 'vue-feather-icons';
import countTo from 'vue-count-to';
import headerImage from '../../components/UI/header_image.vue';
import TrustpilotCarousel from '../../components/UI/trustpilot/carousel.vue';
import header_slider from '../../components/UI/header_slider.vue';

export default {
    components: {
        ArrowUpIcon,
        PlayIcon,
        ClockIcon,
        EyeIcon,
        ShoppingBagIcon,
        StarIcon,
        'vue-typer': VueTyper,
        countTo,
        headerImage,
        TrustpilotCarousel,
        header_slider,
    },
    name: 'Trade-raid.com: Steam Items for WoW Gold & Gold Swap',
    metaInfo: {
        title: 'Trade-raid.com: Steam Items for WoW Gold & Gold Swap',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content: 'Trade your CSGO/DOTA2/Rust/TF2/Steam Items for WoW Retail/Classic gold at Trade-Raid.com',
            },
        ],
    },
    data: () => ({
        isActive: false,
    }),
    mounted() {
        var scripts = ['https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'];
        scripts.forEach((script) => {
            let tag = document.createElement('script');
            tag.setAttribute('src', script);
            document.head.appendChild(tag);
        });
        if (this.$route.query.st) {
            this.$scrollTo(`#${this.$route.query.st}`, 0, { offset: -80 });
        }
    },
};
</script>
<style scoped>
.trust-pilot-padding-fix {
    max-height: 25px;
}
@media screen and (max-width: 1200px) {
    .blog {
        width: 100%;
        height: 100%;
    }
}

.service-container {
    padding-top: 25px;
}

.service {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 50px;
}

@media screen and (max-width: 896px) {
    .service-container {
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .service {
        flex-wrap: wrap;
    }
    .service a {
        /* margin-bottom: 25px; */
        align-items: center;
    }
    .service-info {
        padding-top: 0px !important;
    }
    .service > img {
        width: 75% !important;
    }
    .tr-blog {
        margin-top: 50px;
    }
    .service-image {
        width: 100%;
        height: auto;
    }
}

.row-reverse {
    flex-direction: row-reverse;
}

.align-center {
    align-items: center;
}

.align-baseline {
    display: flex;
    align-items: baseline;
    flex-direction: column;
}

.small-text {
    font-size: 20px !important;
}

.service-image {
    width: 50%;
    height: auto;
}
.service-info {
    padding-top: 50px;
}

.element {
    margin-right: 100px;
}

.game-types > div {
    font-size: 25px;
    font-weight: bold;
}
.g-norm {
    color: #f1f1f1;
}
.g-classic {
    color: #ddc55e;
}

.g-retail {
    color: #3d6fc9;
}

.g-tbc {
    color: #7ab554;
}

#cta a {
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 3px;
    width: 160px;
    padding-top: 13px;
    padding-bottom: 13px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
}

/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}
*,
::after,
::before {
    box-sizing: border-box;
}
footer,
header,
section {
    display: block;
}
h1,
h2,
h4,
h5 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul ul {
    margin-bottom: 0;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #0056b3;
    text-decoration: underline;
}
a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}
img {
    vertical-align: middle;
    border-style: none;
}
svg {
    overflow: hidden;
    vertical-align: middle;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
template {
    display: none;
}
.h1,
.h2,
.h4,
.h5,
h1,
h2,
h4,
h5 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.h1,
h1 {
    font-size: 2.5rem;
}
.h2,
h2 {
    font-size: 2rem;
}
.h4,
h4 {
    font-size: 1.5rem;
}
.h5,
h5 {
    font-size: 1.25rem;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.overwrite-container {
    max-width: 990px !important;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-12,
.col-lg-6,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
@media (min-width: 768px) {
    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-md-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
}
@media (min-width: 992px) {
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    color: #212529;
    text-decoration: none;
}
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn:disabled {
    opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}
.btn-primary:focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.header {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}
.header .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}
.trade-service {
    transform: translate(-12rem, -34rem);
}
.exchange-service {
    transform: translate(2.5rem, -7rem);
}

/* @media screen and (max-width: 992px) {
    .trade-service {
        transform: translate(10px, 10px);
    }
    .exchange-service {
        transform: translate(10px, 10px);
    };
} */

a.badge:focus,
a.badge:hover {
    text-decoration: none;
}
.badge:empty {
    display: none;
}
.btn .badge {
    position: relative;
    top: -1px;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}
a.badge-danger:focus,
a.badge-danger:hover {
    color: #fff;
    background-color: #bd2130;
}
a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}
@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.align-middle {
    vertical-align: middle !important;
}
.bg-primary {
    background-color: #007bff !important;
}
a.bg-primary:focus,
a.bg-primary:hover {
    background-color: #0062cc !important;
}
.bg-success {
    background-color: #28a745 !important;
}
a.bg-success:focus,
a.bg-success:hover {
    background-color: #1e7e34 !important;
}
.bg-light {
    background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover {
    background-color: #dae0e5 !important;
}
.bg-white {
    background-color: #fff !important;
}
.border {
    border: 1px solid #dee2e6 !important;
}
.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}
.border-0 {
    border: 0 !important;
}
.border-light {
    border-color: #f8f9fa !important;
}
.rounded {
    border-radius: 0.25rem !important;
}
.rounded-circle {
    border-radius: 50% !important;
}
.d-inline-block {
    display: inline-block !important;
}
.d-block {
    display: block !important;
}
.d-table {
    display: table !important;
}
.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}
.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}
.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}
.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.position-relative {
    position: relative !important;
}
.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.w-100 {
    width: 100% !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mr-1 {
    margin-right: 0.25rem !important;
}
.mt-2 {
    margin-top: 0.5rem !important;
}
.mr-2 {
    margin-right: 0.5rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.mt-4 {
    margin-top: 1.5rem !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.pt-2 {
    padding-top: 0.5rem !important;
}
.pb-2 {
    padding-bottom: 0.5rem !important;
}
.p-3 {
    padding: 1rem !important;
}
.pt-3 {
    padding-top: 1rem !important;
}
.py-5 {
    padding-top: 3rem !important;
}
.py-5 {
    padding-bottom: 3rem !important;
}
.mx-auto {
    margin-right: auto !important;
}
.mx-auto {
    margin-left: auto !important;
}
@media (min-width: 576px) {
    .mt-sm-0 {
        margin-top: 0 !important;
    }
    .pt-sm-0 {
        padding-top: 0 !important;
    }
}
@media (min-width: 992px) {
    .mt-lg-0 {
        margin-top: 0 !important;
    }
    .mt-lg-4 {
        margin-top: 1.5rem !important;
    }
    .ml-lg-5 {
        margin-left: 3rem !important;
    }
    .pt-lg-0 {
        padding-top: 0 !important;
    }
    .pt-lg-4 {
        padding-top: 1.5rem !important;
    }
}
.text-center {
    text-align: center !important;
}
.font-weight-bold {
    font-weight: 700 !important;
}
.text-primary {
    color: #007bff !important;
}
a.text-primary:focus,
a.text-primary:hover {
    color: #0056b3 !important;
}
.text-light {
    color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
    color: #cbd3da !important;
}
.text-dark {
    color: #343a40 !important;
}
a.text-dark:focus,
a.text-dark:hover {
    color: #121416 !important;
}
.text-muted {
    color: #6c757d !important;
}
@media print {
    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    img {
        page-break-inside: avoid;
    }
    h2,
    p {
        orphans: 3;
        widows: 3;
    }
    h2 {
        page-break-after: avoid;
    }
    @page {
        size: a3;
    }
    .container {
        min-width: 992px !important;
    }
    .header {
        display: none;
    }
    .badge {
        border: 1px solid #000;
    }
}
/*# sourceMappingURL=bootstrap.min.css.map */
/* Template Name: Landrick - Saas & Software Landing Page Template
   Author: Themesbrand
   E-mail: themesbrand@gmail.com
   Version: 2.6
   Updated: November 2020
   File Description: Main Css file of the template
*/
/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 *     07.  Home / Landing       *
 *     08.  Features / Services  *
 *     09.  Testimonial          *
 *     10.  Price                *
 *     11.  C.T.A                *
 *     12.  Blog / Careers / Job *
 *     13.  Work / Projects      *
 *     14.  Team                 *
 *     15.  User Pages           *
 *     16.  Countdown            *
 *     17.  Contact              *
 *     18.  Footer               *
 *********************************
 
 *********************************
 *         Dark SCSS             *
 *********************************
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 ================================*/
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
h1 {
    font-size: 42px !important;
}

h2 {
    font-size: 36px !important;
}

h4 {
    font-size: 24px !important;
}

h5 {
    font-size: 20px !important;
}

.bg-primary {
    background-color: #17d0c12a !important;
}
.icon-class {
    color: #fff;
}

.text-primary {
    color: #17d0c1 !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #284bc1 !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.bg-success {
    background-color: #2eca8b !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.text-dark {
    color: #3c4858 !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #323c49 !important;
}

.text-muted {
    color: #8492a6 !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a.text-muted:hover,
a.text-muted:focus {
    color: #75859b !important;
}

.bg-light {
    background-color: #f8f9fc !important;
}

.text-light {
    color: #f8f9fc !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a.text-light:hover,
a.text-light:focus {
    color: #e6eaf4 !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.rounded {
    border-radius: 6px !important;
}

.border {
    border: 1px solid #e9ecef !important;
}

.border-bottom {
    border-bottom: 1px solid #e9ecef !important;
}

.card .card-body {
    padding: 1.5rem;
}

.btn-primary {
    background-color: #17d0c1 !important;
    border: 1px solid #17d0c1 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #2443ac !important;
    border-color: #2443ac !important;
    color: #ffffff !important;
}

.btn-outline-primary {
    border: 1px solid #17d0c1;
    color: #17d0c1;
    background-color: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #17d0c1;
    border-color: #17d0c1;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 600;
    border-radius: 6px;
}

.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn.btn-icon {
    height: 36px;

    width: 36px;
    line-height: 34px;
    padding: 0;
}

.btn.btn-icon .icons {
    height: 16px;
    width: 16px;
    font-size: 16px;
}

.shadow {
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

.badge-danger {
    background-color: #e43f52 !important;
    color: #ffffff !important;
}

.badge {
    display: inline-block;
    letter-spacing: 0.5px;
    padding: 4px 8px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.badge.badge-pill {
    border-radius: 12px;
}

.badge:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.alert-light {
    background-color: rgba(248, 249, 252, 0.9);
    color: #ffffff;
    border-color: #f8f9fc;
}

.alert {
    padding: 8px 15px;
    border-radius: 6px;
    font-size: 14px;
}

.alert.alert-light {
    background-color: #f8f9fc;
    color: #3c4858;
    border-color: #dee2e6;
}

.alert.alert-pills {
    border-radius: 30px;
    display: inline-block;
}

.alert.alert-pills .content {
    font-weight: 600;
}

.avatar.avatar-small {
    height: 65px;
    width: 65px;
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0;
    }
}

@keyframes animate-positive {
    0% {
        width: 0;
    }
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.back-to-top {
    z-index: 99;
    position: fixed;
    bottom: 30px;
    left: 30px;
    display: none;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.back-to-top .icons {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.back-to-top:hover {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.back-to-top:hover .icons {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

[class^='uil-']:before,
[class*=' uil-']:before {
    margin: 0;
}

h1,
h2,
h4,
h5,
.h1,
.h2,
.h4,
.h5 {
    font-family: 'Nunito', sans-serif;
    line-height: 1.5;
    font-weight: 600;
}

::-moz-selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
}

::selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
}

a {
    text-decoration: none !important;
}

p {
    line-height: 1.6;
}

.section {
    padding: 100px 0;
    position: relative;
}

.sectionProcess {
    padding: 70px 0;
    position: relative;
}

@media (max-width: 768px) {
    .section {
        padding: 60px 0;
    }
}

.title-heading {
    line-height: 26px;
}

.title-heading .heading {
    font-size: 40px !important;
    letter-spacing: 1px;
}

@media (max-width: 768px) {
    .title-heading .heading {
        font-size: 6vw !important;
    }
}

.title-heading .para-desc {
    font-size: 20px;
}

.section-title {
    position: relative;
}

.section-title .title {
    letter-spacing: 0.5px;
    font-size: 30px !important;
}

@media (max-width: 768px) {
    .section-title .title {
        font-size: 24px !important;
    }
}

.para-desc {
    max-width: 600px;
}

.mt-100 {
    margin-top: 100px;
}

@media (max-width: 768px) {
    .mt-60 {
        margin-top: 60px;
    }
}

#header {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
    background-color: transparent;
    border: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

@media (max-width: 991px) {
    #header {
        background-color: #ffffff;
        -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        min-height: 74px;
    }
    #header .container {
        width: auto;
    }
}

.bg-half-170 {
    /* padding: 170px 0;
     */
    padding-top: 100px;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

@media (max-width: 767px) {
    .bg-half-170 {
        padding: 150px 0;
        height: auto;
    }
}

.work-process {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.work-process a {
    font-size: 15px;
}

.work-process:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.play-btn {
    height: 78px;
    width: 78px;
    font-size: 30px;
    line-height: 76px;
    border-radius: 50%;
    display: inline-block;
    color: #ffffff;
}

.play-icon {
    position: absolute;
    bottom: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    right: 0;
    left: 0;
    text-align: center;
}

.play-icon i {
    height: 75px;
    -webkit-text-stroke: 2px #17d0c1;
    -webkit-text-fill-color: transparent;
    width: 75px;
    font-size: 25px;
    line-height: 75px;
    display: inline-block;
}

.play-icon i:hover {
    -webkit-text-stroke: 2px #17d0c1;
    -webkit-text-fill-color: #17d0c1;
}

#clock {
    color: #ffffff;
}

#clock p {
    width: 80px;
}

#clock p span {
    font-size: 40px;
}

.footer {
    background: #202942;
    padding: 60px 0;
    position: relative;
    color: #adb5bd;
}

.bg-white {
    background-color: #1f2d3d !important;
}

.text-primary {
    color: #17d0c1 !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #284bc1 !important;
}

.bg-success {
    background-color: #2eca8b !important;
}

.text-dark {
    color: #ffffff !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #f2f2f2 !important;
}

.text-muted {
    color: #9bacc4 !important;
}

a.text-muted:hover,
a.text-muted:focus {
    color: #8b9fbb !important;
}

.bg-light {
    background-color: #253649 !important;
}

.text-light {
    color: #253649 !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #1c2938 !important;
}

.card {
    background-color: #1f2d3d;
}

.border {
    border: 1px solid #495057 !important;
}

.border.border-light {
    border-color: #ffffff !important;
}

.border-bottom {
    border-bottom: 1px solid #495057 !important;
}

.btn-primary {
    background-color: #17d0c1 !important;
    border: 1px solid #17d0c1 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #2443ac !important;
    border-color: #2443ac !important;
    color: #ffffff !important;
}

.btn-outline-primary {
    border: 1px solid #17d0c1;
    color: #17d0c1;
    background-color: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #17d0c1;
    border-color: #17d0c1;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.shadow {
    -webkit-box-shadow: 0 0 3px rgba(255, 255, 255, 0.15) !important;
    box-shadow: 0 0 3px rgba(255, 255, 255, 0.15) !important;
}

.badge-danger {
    background-color: #e43f52 !important;
    color: #ffffff !important;
}

.alert-light {
    background-color: #2e435a;
    color: #ffffff;
    border-color: #253649;
}

.alert.alert-light {
    background-color: #253649;
    color: #ffffff;
    border-color: #253649;
}

.modal-content {
    background: #1f2d3d;
}

.back-to-top {
    color: #ffffff;
    background-color: #495057;
    -webkit-box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
}

.back-to-top:hover {
    color: #1f2d3d;
}

#clock {
    color: #ffffff;
}

.play-btn {
    color: #1f2d3d;
}

.footer {
    background: #243447;
    color: #adb5bd;
}

::-moz-selection {
    color: #ffffff;
}

::selection {
    color: #ffffff;
}

.title-dark {
    color: #ffffff !important;
}

@media (max-width: 991px) {
    #header {
        background-color: #1f2d3d;
        -webkit-box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.15);
    }
}
/*# sourceMappingURL=style-dark.css.map */
.modal-content {
    background-color: transparent;
}
</style>
